import { gql } from '@apollo/client';

export const CHECK_TRANSFERS_FRAGMENT = gql`
  fragment CheckTransfer on CheckTransfer {
    id
    orderType
    orderStatus
    checkType: type
    checkTransferErrorCode: errorCode
    amount
    retirementContributionInfo {
      type
    }
    isReturned
    createTime
  }
`;

export const CHECK_TRANSFERS_EDGE = gql`
  fragment CheckTransferEdge on CheckTransferEdge {
    node {
      ...CheckTransfer
    }
    cursor
  }
  ${CHECK_TRANSFERS_FRAGMENT}
`;
