import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { MouseEvent } from 'react';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';

// Trabian
import { WebBodyText14 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { TSubmenuEntry } from '@/hooks/utils/useSubmenu';
import cn from '~utils/tailwind';

// FinTron SVGs
import { ChevronRightSvg } from '@fintronners/react-ui/src/Images';

type SubmenuProps = {
  clickHandler?: () => void;
  closeHandler: () => void;
  backButton: React.ReactNode;
  entriesArray: Array<TSubmenuEntry[]>;
  hideChevrons?: boolean;
  pathIndices: number[];
  setPathIndices: React.Dispatch<React.SetStateAction<number[]>>;
  topLevelIndex: number;
};

const Submenu: React.FC<SubmenuProps> = (props) => {
  const router = useRouter();
  const { featureFlags: ff } = useFeatureFlags();

  let entry: TSubmenuEntry[];

  const { entriesArray, topLevelIndex, pathIndices } = props;

  if (topLevelIndex < 0) {
    return;
  }

  switch (pathIndices.length) {
    case 1:
      entry = entriesArray[topLevelIndex][pathIndices[0]].children;
      break;
    case 2:
      entry = entriesArray[topLevelIndex][pathIndices[0]].children[pathIndices[1]].children;
      break;
    default:
      entry = entriesArray[topLevelIndex];

      if (ff.shouldHideSettingsSecurity) {
        entry = entry.filter((e) => e.title !== 'Security');
      }

      break;
  }

  return (
    <ul className="grid gap-4" data-testid="sidebarSubmenu">
      {pathIndices.length > 0 && <li>{props.backButton}</li>}
      {entry.map((item: TSubmenuEntry, index: number) => {
        const isActive = router.asPath === item.url;
        const isCloseAccountPage = router.pathname.endsWith('close-account') && isActive;

        return (
          <li key={index}>
            <Link
              href={item.url}
              className={cn(
                'bg-transparent hover:bg-gray-50 flex flex-row flex-nowrap leading-none px-3 py-2 items-center relative rounded-md',
                { 'bg-primary/10': isActive, 'bg-red/10': isCloseAccountPage },
              )}
              onClick={(e: MouseEvent) => {
                // If there is a click handler, call it.
                if (props.clickHandler) props.clickHandler();

                // If there are children, click to show children.
                if (item.children.length > 0) {
                  props.setPathIndices([...props.pathIndices, index]);
                  e.preventDefault();
                  return false;
                }

                // Otherwise, close the menu and return true.
                props.closeHandler();
                return true;
              }}
            >
              {item.icon && (
                <span className={cn('mr-2 text-grey41', { 'text-primary': isActive })}>
                  {item.icon}
                </span>
              )}
              <WebBodyText14.Medium
                className={cn('text-grey72', {
                  'text-primary': isActive,
                  'text-red': isCloseAccountPage,
                })}
              >
                {item.title}
              </WebBodyText14.Medium>
              {item.children.length > 0 && !props.hideChevrons && (
                <span className="absolute right-2 text-gray-400">
                  <ChevronRightSvg width={12} height={12} />
                </span>
              )}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Submenu;
