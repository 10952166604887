import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import { WebBodyText14, WebCaption10 } from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { ErrorMessage } from '@/components/common/Forms/feedback/ErrorMessage';

import { useIsIRAAccount } from '@/hooks/utils/useCurrentAccountType';
import { Skeleton } from '@/components/common/Skeleton';
import { InfoWithIcon } from '../InfoWithIcon';
import { TransferCurrencyInput } from '../TransferCurrencyInput';
import { OneTimeDepositPreselectedOptions } from './OneTimeDepositPreselectedOptions';
import { ContributionsSelectWidget } from '@/components/common/ContributionSelectWidget/ContributionSelectWidget';

const displayStrings = {
  exceedsDailyLimitError: (limit: string) => `Amount exceeds daily limit ${limit}`,
  maxIraContributionLimitReached: 'Maximum contribution amount has been reached.',
  instantDepositLimit: (limit: string) => `Instant Deposit Limit: ${limit}`,
  instantDepositTimeWindow: 'Same Day Instant Deposit Window: 9:00am - 1:30pm EST',
  agePenalties: 'Withdrawals before the age of 59½ may be subject to 10% penalty.',
  contributionSelectDescription:
    'Your contribution amount will be applied to your contribution year selected below.',
};

export type OneTimeDepositFormProps = {
  isAmountOverDailyLimit: boolean;
  availableInstantDepositLimit: string;
  hasReachedContributionLimit: boolean;
  limitLeft: number;
  isLoading: boolean;
  handleToggleFormState: () => void;
};

const OneTimeDepositForm = memo(
  ({
    isAmountOverDailyLimit,
    availableInstantDepositLimit,
    hasReachedContributionLimit,
    isLoading,
    handleToggleFormState,
  }: OneTimeDepositFormProps) => {
    const { control } = useFormContext();

    const isIRAAccount = useIsIRAAccount();

    return (
      <>
        <TransferCurrencyInput disabled={hasReachedContributionLimit} />
        <OneTimeDepositPreselectedOptions disabled={hasReachedContributionLimit} />
        {!isIRAAccount && isAmountOverDailyLimit && (
          <ErrorMessage hasIcon>
            {displayStrings.exceedsDailyLimitError(
              thousandFormatNumbroCurrency(availableInstantDepositLimit),
            )}
          </ErrorMessage>
        )}
        {isIRAAccount && hasReachedContributionLimit && (
          <ErrorMessage hasIcon>{displayStrings.maxIraContributionLimitReached}</ErrorMessage>
        )}
        {isLoading && <Skeleton className="h-4 w-full" />}
        {!isLoading && !isIRAAccount && (
          <>
            <InfoWithIcon
              message={displayStrings.instantDepositLimit(
                thousandFormatNumbroCurrency(availableInstantDepositLimit),
              )}
            />
            <WebCaption10.Regular className="text-grey55">
              {displayStrings.instantDepositTimeWindow}
            </WebCaption10.Regular>
          </>
        )}
        {!isLoading && isIRAAccount && (
          <>
            <WebBodyText14.Regular className="text-grey72">
              {displayStrings.contributionSelectDescription}
            </WebBodyText14.Regular>
            <Controller
              name="taxYear"
              control={control}
              render={({ field }) => (
                <ContributionsSelectWidget
                  {...field}
                  disabled={
                    false
                    /**
                     * Forcing this field to be enabled since the user should be able
                     * to switch Tax Years even when the rest of the form is disabled
                     */
                  }
                  setSelectedTaxYear={field.onChange}
                  handleToggleFormState={handleToggleFormState}
                />
              )}
            />
          </>
        )}
      </>
    );
  },
);

export default OneTimeDepositForm;
