import i18next from '@fintronners/react-language/src/Lang';

export const orderDisplayStrings = {
  automatedInvestment: i18next.t('common.automatedInvestment'),
  trade: i18next.t('common.trade'),
  orderType: i18next.t('miscScreens.tradeDisplay.tradeOrderTypeLabel'),
  orderTypes: i18next.t('miscScreens.tradeDisplay.tradeScreenOrderTypesInfoTitle'),
  orderDuration: i18next.t('miscScreens.tradeDisplay.tradeOrderOrderDuration'),
  oneDay: i18next.t('miscScreens.tradeDisplay.tradeOrderLimitOneDay'),
  goodForOneDay: i18next.t('miscScreens.tradeOptions.durationOptionsGoodForOneDay'),
  goodTillCancelled: i18next.t('miscScreens.tradeDisplay.tradeOrderLimitTillCancelled'),
  costBasis: i18next.t('common.costBasis'),
  stopLoss: i18next.t('miscScreens.tradeDisplay.tradeOrderStopLoss'),
  stopLimit: i18next.t('miscScreens.tradeDisplay.tradeOrderStopLimit'),
  stopPrice: i18next.t('miscScreens.tradeDisplay.tradeOrderStopPrice'),
  limitBuy: i18next.t('miscScreens.tradeDisplay.tradeOrderLimitBuy'),
  limitPrice: i18next.t('miscScreens.tradeDisplay.tradeOrderLimitPrice'),
  limitOrder: i18next.t('miscScreens.tradeDisplay.tradeOrderLimitOrder'),
  marketBuy: i18next.t('common.marketBuy'),
  marketSell: i18next.t('common.marketSell'),
  buy: i18next.t('common.buy'),
  sell: i18next.t('common.sell'),
  transactionFee: i18next.t('common.transactionFee'),
  shares: i18next.t('common.shares'),
  perShare: i18next.t('common.perShare'),
  amount: i18next.t('common.amount'),
  status: i18next.t('common.status'),
  cancelNotAllowedNotice: i18next.t('miscScreens.activityHistory.popups.cancelNotAllowedNotice'),
};

export const statusDisplayStrings = {
  open: i18next.t('common.open'),
  cancelled: i18next.t('common.cancelled'),
  cancelling: i18next.t('common.cancelling'),
  pending: i18next.t('common.pending'),
  completed: i18next.t('common.completed'),
  returned: i18next.t('common.returned'),
};

export const transferDisplayStrings = {
  deposit: i18next.t('common.deposit'),
  rollover: i18next.t('common.rollover'),
  automatedTypeUnknown: (type: string) => i18next.t('common.automatedTypeUnknown', { type }),
  automatedInvestment: i18next.t('common.automatedInvestment'),
  instantDeposit: i18next.t('common.instantDeposit'),
  threeDayDepositAmount: i18next.t('miscScreens.deposits.threeDayDepositAmount'),
  instantDepositAmount: i18next.t('common.depositAmount'),
  withdrawal: i18next.t('common.withdrawal'),
  transferType: i18next.t('miscScreens.transfers.transferTypeTitle'),
  sector: i18next.t('miscScreens.common.sector'),
  symbol: i18next.t('common.symbol'),
  transferringFrom: i18next.t('miscScreens.activityHistory.popups.transferringFrom'),
  transferringTo: i18next.t('miscScreens.activityHistory.popups.transferringTo'),
  amount: i18next.t('common.amount'),
  totalAmount: i18next.t('common.totalAmount'),
  totalDepositAmount: i18next.t('common.depositAmount'),
  returnedTransfer: i18next.t('miscScreens.transfers.returnedTransfer'),
  returnedTransferFee: i18next.t('miscScreens.transfers.returnedTransferFee'),
  checkTransfer: i18next.t('miscScreens.transfers.checkTransfer'),
  depositNotice: i18next.t('miscScreens.activityHistory.popups.depositNotice'),
};

export const dividendDisplayStrings = {
  transferType: i18next.t('miscScreens.transfers.transferTypeTitle'),
  dividend: i18next.t('common.dividend'),
  received: i18next.t('common.received'),
  security: i18next.t('common.security'),
  amount: i18next.t('common.amount'),
  unknown: i18next.t('common.unknown'),
  dividendReceived: i18next.t('miscScreens.activityHistory.popups.dividendReceived'),
  dividendReinvested: i18next.t('miscScreens.activityHistory.popups.dividendReinvested'),
  qualifiedDividend: i18next.t('miscScreens.activityHistory.popups.qualifiedDividend'),
  qualifiedDividendReinvested: i18next.t(
    'miscScreens.activityHistory.popups.qualifiedDividendReinvested',
  ),
  capitalGainsDistribution: i18next.t(
    'miscScreens.activityHistory.popups.capitalGainsDistribution',
  ),
  capitalGainsDistributionReinvested: i18next.t(
    'miscScreens.activityHistory.popups.capitalGainsDistributionReinvested',
  ),
  returnOfCapitalDistribution: i18next.t(
    'miscScreens.activityHistory.popups.returnOfCapitalDistribution',
  ),
  returnOfCapitalDistributionReinvested: i18next.t(
    'miscScreens.activityHistory.popups.returnOfCapitalDistributionReinvested',
  ),
};

export const interestDisplayStrings = {
  interestPaid: 'Interest paid',
  cashSweepProgram: 'Cash Sweep program',
  fpslProgram: 'FPSL program',
  transferType: 'Transfer type',
  amount: 'Amount',
  receivingFrom: 'Receiving from',
};
