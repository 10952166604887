import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  achAccount: {
    title: 'Funding Source',
    addAccount: 'Add bank account',
    loading: 'Loading...',
  },
  goalSummaryCard: {
    title: 'Robo Fees',
    description: `${FinTronConfig.robo.name} charges an annual fee of 0.50%, which is billed monthly to your account. This means that the 0.50% annual fee is divided into 12 parts, so you are charged a fraction of the fee each month.\n
    For example, if you have an account with an average daily value of $1,000 in a month, your monthly fee would be 0.50% divided by 12 months, which is 0.0417%. This translates to a monthly fee of $0.42.\n
    ${FinTronConfig.robo.name} is committed to providing low-cost, personalized investment advice and support to all of our clients, regardless of the size of their portfolio.`,
  },
  watchlist: {
    title: 'Watchlist',
    info: 'Stocks / ETFs that you are keeping an eye on! Just click the Heart Icon on any security page to add a security to your watchlist!\n\nThe pre-populated securities in the Watchlist are included to educate you on how the Watchlist feature works. FinTron Invest LLC does not recommend securities or investment strategies involving securities.',
    disclosuresText: 'Disclosure',
    disclosuresBody: `By utilizing ${FinTronConfig.parentName} you agree that you are solely responsible for reviewing and understanding the contents of the ${FinTronConfig.parentName} disclosure documents, all of which are provided at [${FinTronConfig.disclosuresUrl}](${FinTronConfig.disclosuresUrl})`,
    watchlistDisclosureTitle: 'Watchlist disclosure',
    watchlistDisclosureBody: `The pre-populated securities in the Watchlist are included to educate you on how the Watchlist feature works. ${FinTronConfig.selfDirected.name} does not recommend securities or investment strategies involving securities.`,
  },
};
