import {
  AchTransferTransferType,
  OrderOrderStatus,
  TradeTradeExpirationType,
  OrderOrderType,
  IctTransferTransferType,
} from '@fintronners/react-api/src/graphql/types/graphql';
import {
  orderDisplayStrings,
  statusDisplayStrings,
  transferDisplayStrings,
} from '@fintronners/react-utils/src/displayStrings';

export const getTradeTradeExpirationTypeDisplayString = (
  expirationType: TradeTradeExpirationType,
): string => {
  switch (expirationType) {
    case TradeTradeExpirationType.TradeExpirationTypeDay:
      return orderDisplayStrings.oneDay;
    case TradeTradeExpirationType.TradeExpirationTypeGoodTilCancel:
      return orderDisplayStrings.goodTillCancelled;
    default:
      return 'Unknown';
  }
};

export const getOrderStatusDisplayString = (
  orderOrderStatus: OrderOrderStatus | undefined,
  showPending = false,
): string => {
  switch (orderOrderStatus) {
    case OrderOrderStatus.OrderStatusFailed:
    case OrderOrderStatus.OrderStatusCanceled:
      return statusDisplayStrings.cancelled;
    case OrderOrderStatus.OrderStatusCanceling:
      return statusDisplayStrings.cancelling;
    case OrderOrderStatus.OrderStatusProcessing:
      if (showPending) {
        // The BE returns OrderOrderStatus.OrderStatusProcessing for both trades
        // and transfers. For trades we show statusDisplayStrings.open but for
        // transfers we show statusDisplayStrings.pending
        return statusDisplayStrings.pending;
      }
      return statusDisplayStrings.open;
    case OrderOrderStatus.OrderStatusFinalizing:
    case OrderOrderStatus.OrderStatusDone:
      return statusDisplayStrings.completed;
    default:
      return 'Unknown';
  }
};

export const getACHTransferTransferTypeDisplayString = (
  transferType: AchTransferTransferType,
): string => {
  switch (transferType) {
    case AchTransferTransferType.TransferTypeDeposit:
      return transferDisplayStrings.deposit;
    case AchTransferTransferType.TransferTypeWithdraw:
      return transferDisplayStrings.withdrawal;
    default:
      return 'Unknown';
  }
};

export const getICTTransferTransferTypeDisplayString = (
  transferType: IctTransferTransferType,
): string => {
  switch (transferType) {
    case IctTransferTransferType.TransferTypeDeposit:
      return transferDisplayStrings.deposit;
    case IctTransferTransferType.TransferTypeWithdraw:
      return transferDisplayStrings.withdrawal;
    default:
      return 'Unknown';
  }
};

export const ORDER_TYPE_DISPLAY_STRING = {
  [OrderOrderType.OrderTypeFeeCredit]: 'Funding',
  [OrderOrderType.OrderTypeLiquidation]: 'Liquidation',
  [OrderOrderType.OrderTypePortfolioLiquidation]: 'Portfolio Liquidation',
  [OrderOrderType.OrderTypeAcattransfer]: 'ACAT Transfer',
  [OrderOrderType.OrderTypeAchtransfer]: 'ACH Transfer',
  [OrderOrderType.OrderTypeAdmin]: 'Admin',
  [OrderOrderType.OrderTypeCorporateAction]: 'Corporate Action',
  [OrderOrderType.OrderTypeDividend]: 'Dividend',
  [OrderOrderType.OrderTypeIcttransfer]: 'ICT Transfer',
  [OrderOrderType.OrderTypePortfolioLedger]: 'Portfolio Ledger',
  [OrderOrderType.OrderTypeRecurringRuleInstance]: 'Recurring Rule',
  [OrderOrderType.OrderTypeTrade]: 'Trade',
  [OrderOrderType.OrderTypeAccountReconAdjustment]: 'Account Recon Adjustment',
  [OrderOrderType.OrderTypeInterest]: 'Interest',
  [OrderOrderType.OrderTypeCapitalGains]: 'Capital Gains',
  [OrderOrderType.OrderTypeCapitalGainsDistribution]: 'Capital Gains Distribution',
  [OrderOrderType.OrderTypeChecktransfer]: 'Check transfer',
};
