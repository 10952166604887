import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';

export default {
  achAccount: {
    title: 'Minha conta',
    addAccount: 'Adicionar conta bancária',
    loading: 'Carregando...',
  },
  goalSummaryCard: {
    title: 'Taxas Robo',
    description: `${FinTronConfig.robo.name} cobra uma taxa anual de 0,50%, que é faturada mensalmente em sua conta. Isso significa que a taxa anual de 0,50% é dividida em 12 partes, então você é cobrado uma fração da taxa a cada mês.\n
    Por exemplo, se você tem uma conta com um valor médio diário de $1.000 em um mês, sua taxa mensal seria 0,50% dividido por 12 meses, o que é 0,0417%. Isso se traduz em uma taxa mensal de $0,42.\n
    ${FinTronConfig.robo.name} está comprometida em fornecer aconselhamento e suporte de investimento personalizado e de baixo custo para todos os nossos clientes, independentemente do tamanho de seu portfólio.`,
  },
  watchlist: {
    title: 'Watchlist',
    info: 'Ações/ETFs que você está acompanhando! Basta clicar no ícone de coração em qualquer página de ativo para adicioná-lo à sua Watchlist!\n\nOs valores mobiliários pré-preenchidos na Watchlist são incluídos para mostrar como a Watchlist funciona. O Bradesco Investments não recomenda valores mobiliários ou estratégias de investimento envolvendo valores mobiliários.',
    disclosuresText: 'Divulgação',
    disclosuresBody: `Ao utilizar o Banco Bradesco, você concorda que é o único responsável por revisar e entender o conteúdo dos documentos de divulgação do Banco Bradesco, que estão disponíveis em [${FinTronConfig.disclosuresUrl}](${FinTronConfig.disclosuresUrl}).`,
    watchlistDisclosureTitle: 'Divulgação de Watchlist',
    watchlistDisclosureBody: `Os valores mobiliários pré-preenchidos na Watchlist são incluídos para demonstrar como a Watchlist funciona. O ${FinTronConfig.selfDirected.name} não recomenda valores mobiliários ou estratégias de investimento envolvendo valores mobiliários.`,
  },
};
