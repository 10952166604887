import { SecurityAssetSecurityType } from '@fintronners/react-api/src';
import {
  OrderDirection,
  SecurityAssetOrderField,
  SecurityAssetOrder,
} from '@fintronners/react-api/src';

import type { SecurityCategory } from './types';

export const SECURITY_CATEGORY = {
  STOCK: 'stock',
  ETF: 'etf',
  MUTUAL_FUND: 'mutual_fund',
} as const;

export const STOCK_SECURITY_TYPES = [
  SecurityAssetSecurityType.Cs,
  SecurityAssetSecurityType.Cvr,
  SecurityAssetSecurityType.Pfd,
];
export const ETF_SECURITY_TYPES = [SecurityAssetSecurityType.Etf, SecurityAssetSecurityType.Etn];
export const MUTUAL_FUND_SECURITY_TYPES = [SecurityAssetSecurityType.MutualFund];

export const INVESTMENT_STYLE_OPTIONS = [
  'Agriculture',
  'All Cap Blend',
  'Allocation/Balanced',
  'Bank Loan',
  'Bear Market Strategy',
  'CommercialServices',
  'Commodities',
  'Communications',
  'ConsumerDurables',
  'ConsumerNonDurables',
  'ConsumerServices',
  'Convertibles',
  'Debt-oriented balanced',
  'DistributionServices',
  'Diversified Sectoral Bond',
  'ElectronicTechnology',
  'Emerging Markets Bond',
  'Emerging Markets Diversified Sectoral Bond',
  'Energy',
  'EnergyMinerals',
  'Equity Tactical Allocation',
  'Equity-oriented Balanced',
  'Finance',
  'Fixed Income Tactical Allocation',
  'Global Diversified Sectoral Bond',
  'Global High Yield Bond',
  'Gold',
  'Government',
  'HealthServices',
  'HealthTechnology',
  'High Yield Bond',
  'High Yield Diversified Sectoral Bond',
  'High Yield Emerging Markets Bond',
  'High Yield Municipal Bond',
  'Inflation Protection',
  'IndustrialServices',
  'Intermediate Term Bond',
  'Intermediate Term Diversified Sectoral Bond',
  'Intermediate Term Government Bond',
  'Intermediate Term High Quality',
  'Intermediate Term Low Quality',
  'Intermediate Term Mid Quality',
  'Intermediate Term Municipal Bond',
  'Intermediate Term US Government Bond',
  'Investment Grade Bond',
  'Investment Grade Government Bond',
  'Large Cap Blend',
  'Large Cap Growth',
  'Large Cap Value',
  'Leverage Strategy',
  'Long Term Bond',
  'Long Term Government Bond',
  'Long Term High Quality',
  'Long Term High Yield Bond',
  'Long Term Inflation Protection',
  'Long Term Low Quality',
  'Long Term Mid Quality',
  'Long Term Municipal Bond',
  'Long-Short Strategy',
  'Managed Futures',
  'Market Neutral Strategy',
  'Mid Cap Blend',
  'Mid Cap Growth',
  'Mid Cap Value',
  'Miscellaneous',
  'Municipal Alabama',
  'Municipal Arizona',
  'Municipal Arkansas',
  'Municipal California',
  'Municipal Colorado',
  'Municipal Connecticut',
  'Municipal Florida',
  'Municipal Georgia',
  'Municipal Hawaii',
  'Municipal Idaho',
  'Municipal Kansas',
  'Municipal Kentucky',
  'Municipal Louisiana',
  'Municipal Maine',
  'Municipal Maryland',
  'Municipal Massachusetts',
  'Municipal Michigan',
  'Municipal Minnesota',
  'Municipal Mississippi',
  'Municipal Missouri',
  'Municipal Montana',
  'Municipal Narragansett',
  'Municipal Nebraska',
  'Municipal New Jersey',
  'Municipal New Mexico',
  'Municipal New York',
  'Municipal North Carolina',
  'Municipal North Dakota',
  'Municipal Ohio',
  'Municipal Oklahoma',
  'Municipal Oregon',
  'Municipal Pennsylvania',
  'Municipal South Carolina',
  'Municipal Tennessee',
  'Municipal Texas',
  'Municipal Utah',
  'Municipal Virginia',
  'Municipal Wisconsin',
  'Multi Alternative',
  'Multicurrency',
  'NA',
  'NonEnergyMinerals',
  'NotClassified',
  'Option Writing',
  'Precious Metals',
  'Preferred Stock',
  'ProcessIndustries',
  'ProducerManufacturing',
  'RetailTrade',
  'Sectors Code',
  'Short Term Bond',
  'Short Term Diversified Sectorial Bond',
  'Short Term Emerging Markets Bond',
  'Short Term Government Bond',
  'Short Term High Quality',
  'Short Term High Yield Bond',
  'Short Term Inflation Protection',
  'Short Term Investment Grade Bond',
  'Short Term Low Quality',
  'Short Term Mid Quality',
  'Short Term Municipal Bond',
  'Single Currency',
  'Silver',
  'Small Cap Blend',
  'Small Cap Growth',
  'Small Cap Value',
  'Tactical Allocation',
  'Target Date',
  'TechnologyServices',
  'Trading-Inverse Commodities',
  'Trading-Inverse Debt',
  'Trading-Inverse Equity',
  'Trading-Leveraged Commodities',
  'Trading-Leveraged Debt',
  'Trading-Leveraged Equity',
  'Trading-Leveraged Miscellaneous',
  'Transportation',
  'US Inflation Protection',
  'Ultrashort Term Bond',
  'Ultrashort Term Government Bond',
  'Ultrashort Term High Yield Bond',
  'Ultra short- Low risk',
  'Unknown',
  'Utilities',
  'Volatility/ Derivative Strategy',
] as const;

export const LOAD_TYPE_OPTIONS = {
  FRONT_END_LOAD: 'Front-end load',
  BACK_END_LOAD: 'Back-end load',
  NO_LOAD: 'No load',
} as const;

export const DEFAULT_SECURITIES_SORTING: Record<
  SecurityCategory,
  Omit<Required<SecurityAssetOrder>, 'byEdgeColumn'>
> = {
  [SECURITY_CATEGORY.STOCK]: {
    direction: OrderDirection.Desc,
    field: SecurityAssetOrderField.MarketCap,
  },
  [SECURITY_CATEGORY.ETF]: {
    direction: OrderDirection.Desc,
    field: SecurityAssetOrderField.MarketCap,
  },
  [SECURITY_CATEGORY.MUTUAL_FUND]: {
    direction: OrderDirection.Desc,
    field: SecurityAssetOrderField.MarketCap,
  },
} as const;
