import { gql } from '@apollo/client';
import { ACH_TRANSFER, ACH_TRANSFER_EDGE } from './achTransfers';
import { TRADE_EDGE_FRAGMENT, TRADE_FRAGMENT } from './trades';
import { DIVIDEND_DISTRIBUTION_EDGE, DIVIDEND_DISTRIBUTION_FRAGMENT } from './dividends';
import { INTEREST_DISTRIBUTION_FRAGMENT } from './interestDistribution';
import { ICT_TRANSFERS_FRAGMENT } from './IctTransfers';
import { CHECK_TRANSFERS_FRAGMENT } from './checkTransfers';

export const GET_ACH_TRANSFER_HISTORY = gql`
  query GetAchTransferHistory(
    $first: Int
    $after: Cursor
    $portfolioId: ID
    $achType: [ACHTransferTransferType!]
    $startDate: Time
    $endDate: Time
  ) {
    pages: achTransfers(
      where: {
        hasPortfolioWith: { id: $portfolioId }
        typeIn: $achType
        createTimeGT: $startDate
        createTimeLT: $endDate
      }
      first: $first
      after: $after
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        ...AchTransferEdge
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${ACH_TRANSFER_EDGE}
`;

export const GET_TRADE_HISTORY = gql`
  query GetTradeHistory(
    $first: Int
    $after: Cursor
    $today: Time!
    $portfolioId: ID
    $tradeType: [TradeMarketSide!]
    $startDate: Time
    $endDate: Time
    $allFilters: [TradeWhereInput!]
  ) {
    pages: trades(
      first: $first
      after: $after
      where: {
        and: $allFilters
        or: [{ statusNEQ: TRADE_STATUS_FAILED }, { createTimeGTE: $today }]
        hasPortfolioWith: { id: $portfolioId }
        sideIn: $tradeType
        createTimeGT: $startDate
        createTimeLT: $endDate
      }
      orderBy: { direction: DESC, field: create_time }
    ) {
      edges {
        ...TradeEdgeFragment
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${TRADE_EDGE_FRAGMENT}
`;

export const GET_DIVIDEND_HISTORY = gql`
  query GetDividendHistory($first: Int, $after: Cursor) {
    pages: dividendDistributions(first: $first, after: $after) {
      edges {
        ...DividendDistributionEdge
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${DIVIDEND_DISTRIBUTION_EDGE}
`;

export const GET_ALL_TRANSACTION_HISTORY = gql`
  query GetAllHistory(
    $first: Int
    $after: Cursor
    $portfolioId: ID
    $todayStart: Time!
    $orderType: [OrderOrderType!]
    $startDate: Time
    $endDate: Time
    $recentPages: Boolean!
  ) {
    recentPages: orders(
      first: 20
      after: $after
      orderBy: { direction: DESC, field: create_time }
      where: {
        hasPortfolioWith: { id: $portfolioId }
        typeIn: [
          ORDER_TYPE_ACHTRANSFER
          ORDER_TYPE_TRADE
          ORDER_TYPE_DIVIDEND
          ORDER_TYPE_INTEREST
          ORDER_TYPE_ICTTRANSFER
          ORDER_TYPE_CHECKTRANSFER
        ]
        or: [{ statusIn: [ORDER_STATUS_PROCESSING] }, { createTimeGT: $todayStart }]
      }
    ) @include(if: $recentPages) {
      edges {
        node {
          id
          __typename
          ... on ACHTransfer {
            ...AchTransfer
          }
          ... on Trade {
            ...TradeFragment
          }
          ... on DividendDistribution {
            ...DividendDistributionFragment
          }
          ... on InterestDistribution {
            ...InterestDistributionFragment
          }
          ... on ICTTransfer {
            ...IctTransfer
          }
          ... on CheckTransfer {
            ...CheckTransfer
          }
        }
      }
    }
    pages: orders(
      first: $first
      after: $after
      orderBy: { direction: DESC, field: create_time }
      where: {
        typeIn: $orderType
        statusIn: [ORDER_STATUS_DONE, ORDER_STATUS_FINALIZING]
        hasPortfolioWith: { id: $portfolioId }
        createTimeGT: $startDate
        createTimeLT: $endDate
      }
    ) {
      edges {
        node {
          id
          __typename
          ... on ACHTransfer {
            ...AchTransfer
          }
          ... on Trade {
            ...TradeFragment
          }
          ... on DividendDistribution {
            ...DividendDistributionFragment
          }
          ... on InterestDistribution {
            ...InterestDistributionFragment
          }
          ... on ICTTransfer {
            ...IctTransfer
          }
          ... on CheckTransfer {
            ...CheckTransfer
          }
        }
      }
      pageInfo {
        startCursor
        hasNextPage
        hasPreviousPage
        endCursor
      }
    }
  }
  ${ACH_TRANSFER}
  ${TRADE_FRAGMENT}
  ${DIVIDEND_DISTRIBUTION_FRAGMENT}
  ${INTEREST_DISTRIBUTION_FRAGMENT}
  ${ICT_TRANSFERS_FRAGMENT}
  ${CHECK_TRANSFERS_FRAGMENT}
`;
