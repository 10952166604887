import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  WebBodyText12,
  WebBodyText14,
  WebBodyText16,
  WebButton16,
} from '@fintronners/react-ui/src/GlobalStyling/webTypography';
import { thousandFormatNumbroCurrency } from '@fintronners/react-utils/src/numberUtilsTSX';
import FinTronConfig from '@fintronners/react-config/src/Config/FinTronConfig';
import { type UseExternalAccount } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/common/Select';
import { Radio } from '@/components/common/Radio/Radio';
import { SheetContent, SheetFooter, SheetTitle } from '@/components/common/Sheet';
import { Button } from '@/components/common/Button';
import ACHAccountWidget from '@/components/features/bankLink/ACHAccountWidget';
import { AccountType } from '@fintronners/react-widgets/src/hooks/useExternalAccounts';

import OneTimeDepositForm from './parts/OneTimeDepositForm';
import { RecurringDepositForm } from './parts/RecurringDepositForm';
import { DepositTypes, TransferFormData, TransferTypes } from './schema';
import { WithdrawalForm } from './parts/WithdrawalForm';
import useFeatureFlags from '@/hooks/config/useFeatureFlags';
import useCurrentAccountType, { useIsIRAAccount } from '@/hooks/utils/useCurrentAccountType';
import useUserAccount from '@/hooks/api/useUserAccount';
import { AchTransferTransferType, UserAccountAccountType } from '@fintronners/react-api/src';
import { useContributions } from '@/hooks/api/useContributions';
import { InfoIcon } from '@/assets/icons';
import Image from 'next/image';
import FirstWithdrawDialog from '@/components/features/withholdingElections/FirstWithdrawDialog';
import InformationDialog from '@/components/features/withholdingElections/InformationDialog';
import { useSidePanel } from '@/context/SidePanelContext';
import { useActivityHistory } from '@/hooks/api/useActivityHistory';

const displayStrings = {
  depositType: 'Deposit type:',
  withdrawalType: 'Withdrawal type:',
  amountLimit: (min: string, max: string) => `Enter amount (between ${min} and ${max}):`,
  withdrawalLimit: (limit: string) => `Enter amount (greater than or equal to ${limit}):`,
  exceedsDailyLimitError: (limit: string) => `Amount exceeds daily limit ${limit}`,
  instantDepositLimit: (limit: string) => `Instant Deposit Limit: ${limit}`,
  instantDepositTimeWindow: 'Same Day Instant Deposit Window: 9:00am - 1:30pm EST',
  withholdingElections: 'Withholding elections',
  bankAccount: 'Bank account',
  continue: 'Continue',
};

type TransferSidePanelFormProps = {
  isLoading: boolean;
  availableInstantDepositLimit: string;
  account: UseExternalAccount | null | undefined;
  handleToggleFormState: () => void;
  onContinue: () => void;
  setFormTransferType: React.Dispatch<TransferTypes>;
};

const { depositMin, withdrawalMin, dailyDepositLimit } = FinTronConfig.selfDirected.transfers;

export const TransferSidePanelForm = ({
  isLoading,
  availableInstantDepositLimit,
  account,
  onContinue,
  handleToggleFormState,
  setFormTransferType,
}: TransferSidePanelFormProps) => {
  const [showFirstWithdrawDialog, setShowFirstWithdrawDialog] = useState(false);
  const [showInformationDialog, setShowInformationDialog] = useState(false);
  const [isAccountValid, setIsAccountValid] = useState(true);
  const { openSidePanel } = useSidePanel();

  const handleOpenFirstWithdrawDialog = useCallback(() => setShowFirstWithdrawDialog(true), []);

  const { control, setValue } = useFormContext<TransferFormData>();
  const [amount, depositType, transferType, availableWithdrawalAmount, taxYear] = useWatch({
    control,
    name: ['amount', 'depositType', 'transferType', 'availableWithdrawalAmount', 'taxYear'],
  });

  const isIRAAccount = useIsIRAAccount();
  const isWithdrawTransfer = transferType === TransferTypes.WITHDRAWAL;
  const isDepositTransfer = transferType === TransferTypes.DEPOSIT;

  useEffect(() => {
    setFormTransferType(transferType);
  }, [transferType]);

  const { overall } = useContributions({ taxYear: taxYear?.toString() });
  const accountType = useCurrentAccountType();
  const { userAccount, loading: accountLoading } = useUserAccount(accountType);

  const { data } = useActivityHistory({
    skip: !userAccount || !isIRAAccount || isWithdrawTransfer,
    portfolioId: userAccount?.firstPortfolioId,
    scrollableRef: null,
    filters: {
      achType: AchTransferTransferType.TransferTypeWithdraw,
    },
  });

  const isFirstWithdrawal = data?.pages?.edges?.length === 0;

  const parsedAmount = parseFloat(amount);
  const isAmountOverContributionLimit = parsedAmount > overall.limitLeft;
  const hasReachedContributionLimit =
    parseFloat(overall?.contributions?.totals.combined.totalWithPending) >= overall.limitLeft;
  const isAmountOverDailyLimit = parsedAmount > Math.ceil(parseFloat(availableInstantDepositLimit));
  const isAmountZero = parsedAmount === 0;
  const isAmountOverAvailable = parsedAmount > parseFloat(availableWithdrawalAmount);

  const commomValidation = !account || !isAccountValid || !amount || isAmountZero;

  const isIraContributionsDisabled =
    isIRAAccount &&
    (isAmountOverContributionLimit || hasReachedContributionLimit || commomValidation);

  const isRegularDepositDisabled = !isIRAAccount && (commomValidation || isAmountOverDailyLimit);

  const isDepositSubmitDisabled = isIraContributionsDisabled || isRegularDepositDisabled;

  const isWithdrawalSubmitDisabled = commomValidation || isAmountOverAvailable;

  const isSubmitDisabled = isDepositTransfer ? isDepositSubmitDisabled : isWithdrawalSubmitDisabled;

  const { featureFlags: ff } = useFeatureFlags();

  return (
    <React.Fragment>
      <SheetContent>
        <div>
          <ul className="flex list-none items-center gap-4 border-b py-6">
            {Object.values(TransferTypes).map((type) => (
              <li key={type}>
                <Controller
                  name="transferType"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <>
                      <Radio.Input
                        id={type}
                        value={type}
                        onChange={() => {
                          setValue('depositType', DepositTypes.ONE_TIME);
                          onChange(type);
                        }}
                        checked={value === type}
                      />
                      <Radio.Label variant="primary" htmlFor={type}>
                        <WebButton16.Bold className="first-letter:capitalize">
                          {type}
                        </WebButton16.Bold>
                      </Radio.Label>
                    </>
                  )}
                />
              </li>
            ))}
          </ul>

          <SheetTitle>
            <WebBodyText16.Bold className="my-2 text-darkestBlue">
              {isDepositTransfer ? displayStrings.depositType : displayStrings.withdrawalType}
            </WebBodyText16.Bold>
          </SheetTitle>

          <div className="mb-2">
            <Controller
              control={control}
              name="depositType"
              render={({ field: { value, onChange } }) => (
                <Select
                  value={value}
                  defaultValue={value}
                  onValueChange={onChange}
                  disabled={
                    isWithdrawTransfer ||
                    accountType !== UserAccountAccountType.AccountTypeBrokerage
                  }
                >
                  <SelectTrigger>
                    <SelectValue>
                      <p className="first-letter:capitalize">{value.replace(/-/g, ' ')}</p>
                    </SelectValue>
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {Object.values(DepositTypes).map((type) => {
                        if (type === 'recurring-deposit' && ff.shouldHideTransferRecurrentDeposit)
                          return null;

                        return (
                          <SelectItem key={type} value={type} className="first-letter:capitalize">
                            <p className="first-letter:capitalize">{type.replace(/-/g, ' ')}</p>
                          </SelectItem>
                        );
                      })}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              )}
            />
          </div>

          <WebBodyText14.Regular className="flex items-center gap-1 text-grey72">
            {isDepositTransfer &&
              isIRAAccount &&
              overall.limitLeft > 0 &&
              displayStrings.amountLimit(
                thousandFormatNumbroCurrency(depositMin, { trimMantissa: true }),
                thousandFormatNumbroCurrency(overall.limitLeft, {
                  mantissa: 0,
                }),
              )}
            {isDepositTransfer &&
              !isIRAAccount &&
              displayStrings.amountLimit(
                thousandFormatNumbroCurrency(depositMin, { trimMantissa: true }),
                thousandFormatNumbroCurrency(dailyDepositLimit, {
                  mantissa: 0,
                }),
              )}

            {isWithdrawTransfer &&
              displayStrings.withdrawalLimit(
                thousandFormatNumbroCurrency(withdrawalMin, { trimMantissa: true }),
              )}
          </WebBodyText14.Regular>
          <div className="mb-4 space-y-4 border-b pb-4">
            {isDepositTransfer ? (
              <>
                {depositType === DepositTypes.ONE_TIME ? (
                  <OneTimeDepositForm
                    availableInstantDepositLimit={availableInstantDepositLimit}
                    isAmountOverDailyLimit={isAmountOverDailyLimit}
                    isLoading={isLoading}
                    handleToggleFormState={handleToggleFormState}
                    hasReachedContributionLimit={hasReachedContributionLimit}
                    limitLeft={overall.limitLeft}
                  />
                ) : null}

                {depositType === DepositTypes.RECURRING_DEPOSIT ? (
                  <RecurringDepositForm
                    isAmountOverDailyLimit={isAmountOverDailyLimit}
                    availableInstantDepositLimit={availableInstantDepositLimit}
                    isLoading={isLoading}
                  />
                ) : null}
              </>
            ) : (
              <WithdrawalForm account={account} />
            )}
          </div>

          <WebBodyText16.Bold className="my-2 text-darkestBlue">
            {displayStrings.bankAccount}
          </WebBodyText16.Bold>

          <div className="flex flex-col gap-3">
            <ACHAccountWidget
              accountType={AccountType.BROKERAGE}
              loading={accountLoading}
              setIsAccountValid={setIsAccountValid}
            />
          </div>
        </div>

        <SheetFooter>
          <div className="flex w-full flex-col">
            {accountType === UserAccountAccountType.AccountTypeIra && isWithdrawTransfer && (
              <Button
                variant="ghost"
                className="mb-5 w-fit self-center border-none"
                onClick={() => setShowInformationDialog((isOpen) => !isOpen)}
              >
                <Image alt="Info icon" width={14} height={14} src={InfoIcon.src} className="mr-1" />
                <WebBodyText12.Regular className="font-normal text-grey41">
                  {displayStrings.withholdingElections}
                </WebBodyText12.Regular>
              </Button>
            )}
            <Button
              variant="primary"
              className="w-full"
              onClick={
                isWithdrawTransfer && isFirstWithdrawal ? handleOpenFirstWithdrawDialog : onContinue
              }
              disabled={isSubmitDisabled}
            >
              {displayStrings.continue}
            </Button>
          </div>
        </SheetFooter>
      </SheetContent>
      <FirstWithdrawDialog
        open={showFirstWithdrawDialog}
        setModalOpen={setShowFirstWithdrawDialog}
        onReviewWithholdingElections={() =>
          openSidePanel('WithholdingElections', undefined, 'Transfer')
        }
      />
      <InformationDialog
        open={showInformationDialog}
        setModalOpen={setShowInformationDialog}
        onEdit={() => openSidePanel('WithholdingElections', undefined, 'Transfer')}
      />
    </React.Fragment>
  );
};
